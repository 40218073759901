import { Box, Card, CardActionArea, CardActions, CardContent, CircularProgress, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

function FriendListItem(props) {
  const { friend, webSocketSend } = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onFriendClick = () => {
    navigate("/app/chat", { state: { target: friend.fromUser.email } });
  };

  const onAcceptClick = () => {
    setIsLoading(true);
    webSocketSend({
      type: "ACCEPT_FRIEND_REQUEST",
      body: {
        fromUser: friend.fromUser,
        toUser: friend.toUser,
      },
    });
  };

  return (
    <Card sx={{ m: 0.4, width: "250px" }}>
      <CardActionArea onClick={onFriendClick}>
        <CardContent>
          <Typography>{friend.fromUser.username}</Typography>
          <Typography variant="body2" color="text.secondary" textOverflow="ellipsis" overflow="clip">
            {friend.fromUser.email}
          </Typography>
        </CardContent>
      </CardActionArea>
      {friend.status == "PENDING" && (
        <CardActions sx={{ justifyContent: "space-evenly", alignItems: "center" }}>
          {!isLoading && (
            <IconButton onClick={onAcceptClick}>
              <DoneIcon></DoneIcon>
            </IconButton>
          )}
          {!isLoading && (
            <IconButton>
              <CloseIcon></CloseIcon>
            </IconButton>
          )}
          {isLoading && <CircularProgress color="inherit" sx={{ m: 1 }} />}
        </CardActions>
      )}
    </Card>
  );
}

export default FriendListItem;
