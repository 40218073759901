import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      paper: "#2f2f2f",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(61, 61, 61)",
          ":hover": "rgb(108, 108, 108)",
          color: "rgb(255, 255, 255)",
        },
      },
    },
  },
});

export default theme;
