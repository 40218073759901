import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  username: "",
  email: "",
  profilePictureUrl: "",
  idToken: "",
};

export const googleUserSlice = createSlice({
  name: "googleUser",
  initialState,
  reducers: {
    logIn: (state, action) => {
      state.isLoggedIn = true;
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.profilePictureUrl = action.payload.profilePictureUrl;
      state.idToken = action.payload.idToken;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      state.username = "";
      state.email = "";
      state.profilePictureUrl = "";
      state.idToken = "";
    },
  },
});

export const { logIn, logOut } = googleUserSlice.actions;

export default googleUserSlice.reducer;
