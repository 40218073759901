import { Box, Typography } from "@mui/material";
import React from "react";

function CustomFooter() {
  return (
    <footer>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "row",
          justifyContent: "center",
          p: 2,
          alignItems: "center",
        }}
      >
        <img
          alt="tuvi"
          src="https://cdn.discordapp.com/attachments/698132712090763284/1121469877518094466/TUVI_V5_BG_TRNS.png"
          width="30px"
        ></img>
        <Typography variant="caption" marginX={2}>
          Famti - 2023
        </Typography>
      </Box>
    </footer>
  );
}

export default CustomFooter;
