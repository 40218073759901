import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import BasePage from "../components/BasePage";
import AddIcon from "@mui/icons-material/Add";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FriendListItem from "../components/FriendListItem";
import { getFriendList } from "../utility/apiCalls";

const dummyFriendList = [];

const WS_URL = "wss://ilfuapz44f.execute-api.eu-west-2.amazonaws.com/production";

function AppPage() {
  const navigate = useNavigate();
  const [friendList, setFriendList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isRequestPopupOpen, setIsRequestPopupOpen] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const idToken = useSelector((state) => state.googleUser.idToken);
  const email = useSelector((state) => state.googleUser.email);
  const username = useSelector((state) => state.googleUser.username);

  const getWebSocketUrl = () => {
    return `${WS_URL}?Authorization=${encodeURIComponent(idToken)}`;
  };

  const { sendJsonMessage, readyState } = useWebSocket(getWebSocketUrl(), {
    share: true,
    onOpen: () => {
      console.log("WebSocket connection established.");
    },
    onMessage: (event) => {
      if (!event.data) {
        return;
      }

      console.log(event.data);

      const incomingMessage = JSON.parse(event.data);

      if (incomingMessage.message === "Internal server error") {
        console.log("INTERNAL SERVER ERROR. Incoming message: ", incomingMessage);
        return;
      }

      if (incomingMessage.type === "FRIEND_REQUEST") {
        console.log(incomingMessage);
        toast.info(incomingMessage.body.fromUser.username + " has sent you a friend request!");
        getFriendList(idToken).then((result) => {
          setFriendList(result);
        });
        return;
      }

      if (incomingMessage.type === "MESSAGE") {
        console.log(incomingMessage.type);
        toast.info("New message from " + incomingMessage.username);
        return;
      }

      if (incomingMessage.type === "FRIEND_REQUEST_ACCEPTED") {
        console.log(incomingMessage.type);
        getFriendList(idToken).then((result) => {
          setFriendList(result);
        });
        return;
      }
    },
    shouldReconnect: (closeEvent) => true,
  });

  const onEmailInputChange = (event) => {
    setEmailInput(event.target.value);
  };

  const handleRequestPopupOpen = () => {
    setIsRequestPopupOpen(true);
  };

  const handleRequestPopupClose = () => {
    setIsRequestPopupOpen(false);
  };

  const handleRequestSend = () => {
    sendJsonMessage({
      type: "FRIEND_REQUEST",
      body: {
        fromUser: { email: email, username: username },
        toUser: { email: emailInput },
      },
    });
    setIsRequestPopupOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getFriendList(idToken).then((result) => {
      setIsLoading(false);
      setFriendList(result);
    });
  }, []);

  return (
    <BasePage>
      <Box sx={{ backgroundColor: "background.paper", borderRadius: 2, minWidth: "230px", minHeight: "230px", p: 1 }}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <ToastContainer position="top-right"></ToastContainer>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item marginX="28px"></Grid>
            <Grid item>
              <Typography m={1}>Friend List</Typography>
            </Grid>
            <Grid item marginX={1}>
              <IconButton onClick={handleRequestPopupOpen}>
                <AddIcon></AddIcon>
              </IconButton>
              <Dialog open={isRequestPopupOpen} onClose={handleRequestPopupClose} maxWidth="sm" fullWidth>
                <DialogTitle>Friend Request</DialogTitle>
                <DialogContent>
                  <TextField
                    id="popup-username"
                    value={emailInput}
                    onChange={onEmailInputChange}
                    label="Email"
                    type="email"
                    autoFocus
                    margin="dense"
                    variant="standard"
                    fullWidth
                  ></TextField>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleRequestPopupClose}>Cancel</Button>
                  <Button onClick={handleRequestSend}>Send Request</Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
          <Divider sx={{ backgroundColor: "#a4a4a4", marginBottom: 1 }} flexItem variant="middle" />
          {isLoading && <CircularProgress color="inherit" sx={{ m: 2 }} />}
          {friendList &&
            friendList.map((friend, index) => (
              <Grid item key={index}>
                <FriendListItem friend={friend} webSocketSend={sendJsonMessage}></FriendListItem>
              </Grid>
            ))}
        </Grid>
      </Box>
    </BasePage>
  );
}

export default AppPage;
