import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { logIn, logOut } from "../redux/googleUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BasePage from "../components/BasePage";

function HomePage() {
  const isLoggedIn = useSelector((state) => state.googleUser.isLoggedIn);
  const idToken = useSelector((state) => state.googleUser.idToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onGoogleLoginSuccess = (response) => {
    console.log("response: ", response);
    const userCredentials = jwtDecode(response.credential);
    console.log("userCreds: ", userCredentials);

    dispatch(
      logIn({
        username: userCredentials.name,
        email: userCredentials.email,
        profilePictureUrl: userCredentials.picture,
        idToken: response.credential,
      })
    );
    navigate("/app");
  };

  const onGoogleLoginError = (response) => {
    console.log(response);
  };

  const gLogin = useGoogleLogin({
    onSuccess: (response) => console.log("Success: ", response),
    onError: (response) => console.log("Error: ", response),
  });

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(logOut());
      return;
    }
    if (!idToken) {
      dispatch(logOut());
      return;
    }
    let jwt = jwtDecode(idToken);
    if (!jwt) {
      dispatch(logOut());
      return;
    }
    if (!jwt.exp || !jwt.nbf) {
      dispatch(logOut());
      return;
    }
    let now = new Date().getTime() / 1000;
    if (jwt.exp <= now - 30) {
      dispatch(logOut());
      return;
    }
    if (jwt.nbf > now + 30) {
      dispatch(logOut());
      return;
    }
    navigate("/app");
  }, []);

  return (
    <BasePage>
      <GoogleLogin useOneTap={false} onSuccess={onGoogleLoginSuccess} onError={onGoogleLoginError}></GoogleLogin>
    </BasePage>
  );
}

export default HomePage;
