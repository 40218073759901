const getFormattedDate = (timestamp) => {
  var date = new Date(timestamp);
  var rawMonth = date.getUTCMonth() + 1;
  var month = rawMonth < 10 ? "0" + rawMonth : rawMonth;
  var day = date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate();
  return day + "." + month + "." + date.getUTCFullYear();
};

const getTime = (timestamp) => {
  var date = new Date(timestamp);
  var hours = date.getUTCHours() + 1 < 10 ? "0" + date.getUTCHours() : date.getUTCHours();
  var mins = date.getUTCMinutes() < 10 ? "0" + date.getUTCMinutes() : date.getUTCMinutes();
  return hours + ":" + mins;
};

export { getFormattedDate, getTime };
