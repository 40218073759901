import "./App.css";
import { Route, Routes } from "react-router-dom";
import ChatPage from "./pages/ChatPage";
import HomePage from "./pages/HomePage";
import AppPage from "./pages/AppPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage></HomePage>}></Route>
      <Route path="/app" element={<AppPage></AppPage>}></Route>
      <Route path="/app/chat" element={<ChatPage></ChatPage>}></Route>
    </Routes>
  );
}

export default App;
