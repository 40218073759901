import axios from "axios";

const BASE_URL = "https://34q7wbr6h6.execute-api.eu-west-2.amazonaws.com/production";

async function getFriendList(authToken) {
  try {
    const result = await axios.get(BASE_URL + "/friends", {
      headers: { Authorization: authToken },
    });
    console.log(result);
    return result.data;
  } catch (error) {}
}

async function getChatHistory(groupId, idToken) {
  try {
    const result = await axios.get(BASE_URL + "/chat-history?groupId=" + groupId, { headers: { Authorization: idToken } });
    console.log(result);
    return result.data;
  } catch (error) {}
}

export { getFriendList, getChatHistory };
