import { Box, Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import useWebSocket from "react-use-websocket";
import { v4 as uuidv4 } from "uuid";
import MessageBox from "../components/MessageBox";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import BasePage from "../components/BasePage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getChatHistory } from "../utility/apiCalls";

var rState = {
  "-1": "UNINSTANTIATED",
  0: "CONNECTING",
  1: "OPEN",
  2: "CLOSING",
  3: "CLOSED",
};

const WS_URL = "wss://ilfuapz44f.execute-api.eu-west-2.amazonaws.com/production";

function ChatPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { target } = location.state;
  const [messageInput, setMessageInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [onlineList, setOnlineList] = useState([]);
  const [isChatLoading, setIsChatLoading] = useState(true);
  const idToken = useSelector((state) => state.googleUser.idToken);
  const email = useSelector((state) => state.googleUser.email);
  const username = useSelector((state) => state.googleUser.username);
  const scrollRef = useRef(null);
  let audio = new Audio("/famti-notif.mp3");
  audio.volume = 0.15;

  const playNotificationSound = () => {
    try {
      audio.play();
    } catch (error) {}
  };

  const getWebSocketUrl = () => {
    return `${WS_URL}?Authorization=${encodeURIComponent(idToken)}`;
  };

  const getGroupId = (senderId, targets) => {
    return Array.from(new Set([...targets, senderId]))
      .sort()
      .join("_");
  };

  const { sendMessage, sendJsonMessage, readyState } = useWebSocket(getWebSocketUrl(), {
    share: true,
    onOpen: () => {
      console.log("WebSocket connection established.");
    },
    onMessage: (event) => {
      if (!event.data) {
        return;
      }

      const incomingMessage = JSON.parse(event.data);

      if (incomingMessage.message === "Internal server error") {
        console.log("INTERNAL SERVER ERROR. Incoming message: ", incomingMessage);
        return;
      }

      if (incomingMessage.type === "MESSAGE_SENT") {
        console.log(incomingMessage);
        setMessages(
          messages.map((msg) =>
            msg.clientUuid === incomingMessage.clientUuid ? { ...msg, timestamp: incomingMessage.timestamp, isLoading: false } : msg
          )
        );
        return;
      }

      if (incomingMessage.type === "FRIEND_REQUEST") {
        console.log(incomingMessage);
        toast.info(incomingMessage.body.fromUsername + " has sent you a friend request!");
      }

      if (incomingMessage.type === "ONLINE_LIST") {
        console.log(incomingMessage);
        setOnlineList(incomingMessage.activeSessionIds);
        return;
      }

      setMessages((messages) => [...messages, incomingMessage]);
      playNotificationSound();
    },
    shouldReconnect: (closeEvent) => true,
  });

  const onMessageInputChange = (event) => {
    setMessageInput(event.target.value);
  };

  const inputAccept = (event) => {
    if (event.nativeEvent.keyCode === 13 || event.keyCode === 13 || event.key === "Enter") {
      event.preventDefault();

      let msg = messageInput.trim();

      if (msg === "") {
        return;
      }

      if (readyState !== 1) {
        console.log("Could not send message, websocket state: ", rState[readyState]);
        return;
      }

      const msgToSend = {
        clientUuid: uuidv4(),
        username: username,
        body: msg,
        targets: [email, target],
      };

      // sendMessage(textFieldVar);
      sendJsonMessage(msgToSend);
      let newMsg = msgToSend;
      newMsg.isLoading = true;
      setMessages((messages) => [...messages, newMsg]);

      setMessageInput("");
    }
  };

  useEffect(() => {
    scrollRef.current.scrollIntoView();
  }, [messages]);

  useEffect(() => {
    console.log(idToken);
    if (idToken) {
      setIsChatLoading(true);
      getChatHistory(getGroupId(email, [target]), idToken).then((res) => {
        setMessages(res);
        setIsChatLoading(false);
      });
    }
  }, [idToken]);

  return (
    <BasePage>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <ToastContainer></ToastContainer>
        {isChatLoading && (
          <Grid item>
            <CircularProgress></CircularProgress>
          </Grid>
        )}
        {messages.map((msg, index) =>
          // <Typography>{msg}</Typography>
          msg.username != username ? (
            <Grid item container key={index} justifyContent="flex-start">
              <MessageBox username={msg.username} message={msg.body} timestamp={msg.timestamp} isLoading={msg.isLoading}></MessageBox>
            </Grid>
          ) : (
            <Grid item container key={index} justifyContent="flex-end" display="flex">
              <MessageBox username={msg.username} message={msg.body} timestamp={msg.timestamp} isLoading={msg.isLoading}></MessageBox>
            </Grid>
          )
        )}
        <Grid item>
          <Typography>{target}</Typography>
        </Grid>
        <Grid item>
          <TextField
            id="message-input"
            label="Message"
            value={messageInput}
            multiline
            maxRows={4}
            fullWidth
            autoFocus
            variant="standard"
            onChange={onMessageInputChange}
            onKeyDown={inputAccept}
          />
        </Grid>
        <Box ref={scrollRef}></Box>
      </Grid>
    </BasePage>
  );
}

export default ChatPage;
