import { Box } from "@mui/material";
import React from "react";
import CustomNavbar from "./CustomNavbar";
import CustomFooter from "./CustomFooter";

function BasePage({ children }) {
  return (
    <Box height="100vh" display="flex" flexDirection="column" justifyContent="space-between">
      <Box>
        <CustomNavbar></CustomNavbar>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", m: 2 }}>
          <Box sx={{ width: "40vw", display: "flex", justifyContent: "center" }}>{children}</Box>
        </Box>
      </Box>
      <CustomFooter></CustomFooter>
    </Box>
  );
}

export default BasePage;
