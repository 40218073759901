import { AppBar, Box, Button, Divider, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logOut } from "../redux/googleUserSlice";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

function CustomNavbar() {
  const isLoggedIn = useSelector((state) => state.googleUser.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <Box sx={{ flexGrow: 1, flexDirection: "row" }}>
      <AppBar position="fixed">
        <Toolbar>
          <img
            alt="tuvi"
            src="https://cdn.discordapp.com/attachments/698132712090763284/1121469877518094466/TUVI_V5_BG_TRNS.png"
            width="40px"
          ></img>
          <Typography marginX={2} variant="h6" component="div">
            Famti
          </Typography>
          <Divider orientation="vertical" flexItem variant="middle"></Divider>
          {location.pathname.includes("chat") ? (
            <IconButton
              onClick={() => {
                navigate("/app");
              }}
            >
              <NavigateBeforeIcon></NavigateBeforeIcon>
            </IconButton>
          ) : (
            <></>
          )}
          <Typography sx={{ flexGrow: 1 }}></Typography>
          {isLoggedIn ? (
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Button
                onClick={() => {
                  dispatch(logOut());
                  navigate("/");
                }}
              >
                Log out
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
}

export default CustomNavbar;
