import { Box, Card, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import { getFormattedDate, getTime } from "../utility/DateFormatter";

function MessageBox({ username, message, timestamp, isLoading }) {
  return (
    <Box display="flex" m={1} maxWidth="80%">
      <Card
        style={{
          bgcolor: "background.paper",
          minWidth: "200px",
        }}
      >
        <Grid container px={1} py={0.5} flexDirection="column">
          <Grid item container justifyContent="space-between">
            <Typography fontSize={12} color="lightgray">
              {username}
            </Typography>
            <Typography variant="subtitle2" color="gray" align="right" fontSize={10}>
              {!isLoading ? getFormattedDate(timestamp) : ""}
            </Typography>
          </Grid>
          <Grid item my={0.3}>
            <Typography style={{ wordBreak: "break-word" }}>{message}</Typography>
          </Grid>
          <Grid item alignSelf="flex-end">
            <Typography variant="subtitle2" color="gray" fontSize={10}>
              {!isLoading ? getTime(timestamp) : <CircularProgress size={12}></CircularProgress>}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}

export default MessageBox;
